import { useNavigate } from 'react-router'
import { useContext, useState } from 'react'
import styles from './HomePage.module.scss'
import { Banner, Loader, PageTitle, TextField, Button } from '../../components'
import { getResult } from '../../requests'
import { AppState } from '../../context'
import { asinExtractor, keywordsPreparer } from '../../helpers'

const HomePage = () => {
    const { setAppState } = useContext(AppState)
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState('')
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const inputHandler = (e) => {
        if (error) {
            setError(null)
        }
        setInputValue(e.target.value)
    }

    const onNextClick = (e) => {
        setError(null)
        e.preventDefault()
        let asin = null

        if (inputValue.includes('www.amazon.com')) {
            const match = inputValue.match(asinExtractor)
            if (match) {
                asin = match[1]
            } else {
                return setError(
                    "Link doesn't includes the ASIN, type correct link"
                )
            }
        }

        if (inputValue.length === 10) {
            asin = inputValue
        }

        if (!asin) {
            return setError('You need to type ASIN or product link on Amazon')
        }

        setIsLoading(true)
        getResult(asin)
            .then(({ data }) => {
                if (data?.status_code === 404) {
                    return setError('Bad request')
                }
                const keywords = keywordsPreparer(data)
                setAppState((prev) => ({
                    ...prev,
                    keywords,
                }))
                navigate('/keywords')
            })
            .catch(() => setError('Something wrong'))
            .finally(() => {
                setIsLoading(false)
                localStorage.setItem('asin', asin)
            })
    }

    return (
        <main className={styles.home_page}>
            <section className={styles.main}>
                <PageTitle>ASIN search ROI calculator</PageTitle>
                <form className={styles.search_form}>
                    <TextField
                        label="Url or product ASIN"
                        onChange={inputHandler}
                        value={inputValue}
                        error={error}
                    />
                    <Button
                        type="submit"
                        disabled={isLoading}
                        onClick={(e) => onNextClick(e)}
                    >
                        Next
                    </Button>
                </form>
            </section>
            <section className={styles.side}>
                {isLoading && <Loader>Searching for Keywords...</Loader>}
                {!isLoading && <Banner />}
            </section>
        </main>
    )
}

export { HomePage }
