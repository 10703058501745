import * as yup from 'yup'
import { IMask } from 'react-imask'

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const asinExtractor = /\/dp\/([A-Z0-9]+)/

export const validationSchema = yup.object({
    name: yup.string().required('Required').trim(),
    email: yup
        .string()
        .email('Must be a valid email')
        .required('Required')
        .trim(),
    phone: yup.string().required('Required').trim(),
})

export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean)
    if (!filteredRefs.length) {
        return null
    }
    if (filteredRefs.length === 1) {
        return filteredRefs[0]
    }

    return (inst) =>
        filteredRefs.forEach((filteredRef) => {
            if (typeof filteredRef === 'function') {
                filteredRef(inst)
            } else if (filteredRef) {
                filteredRef.current = inst
            }
        })
}

export const rounded = (number) => Math.round(parseFloat(number) * 100) / 100

export const thousandsMask = IMask.createMask({
    mask: Number,
    thousandsSeparator: ',',
})

export const keywordsPreparer = (data = []) => {
    return data.reduce((result, keyword, _, array) => {
        if (!result.some((elem) => elem.keyword === keyword.keyword)) {
            result.push({
                keyword: keyword.keyword,
                selected: true,
                values: array.filter(
                    (value) => value.keyword === keyword.keyword
                ),
            })
        }

        return result
    }, [])
}
