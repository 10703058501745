import { Bar, ComposedChart, Line, Tooltip, XAxis } from 'recharts'
import { forwardRef } from 'react'
import { CustomTooltip } from './components/CustomTooltip'
import { monthNames } from '../../values'

const ResultsChart = forwardRef(({ data = [] }, ref) => {
    return (
        <ComposedChart width={750} height={280} data={data} ref={ref}>
            <XAxis
                dataKey="month"
                tickLine={false}
                axisLine={false}
                fontSize={13}
                interval={0}
                tickFormatter={(value) => monthNames[value]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="pv" barSize={27} fill="#F9FAFB" />
            <Line
                type="monotone"
                dataKey="sales_v"
                name="Sales Volume"
                stroke="rgba(106, 176, 245, 0.7)"
                strokeWidth={3}
            />
        </ComposedChart>
    )
})

export { ResultsChart }
