import styles from './Banner.module.scss'
import bannerSrc from '../../assets/images/charts.png'

const Banner = () => (
    <div className={styles.banner}>
        <img className={styles.banner__image} src={bannerSrc} alt="charts" />
        <h3 className={styles.banner__title}>
            We will send ROI Calculation Results to you
        </h3>
    </div>
)

export { Banner }
