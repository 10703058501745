import axios from 'axios'
import { axiosClient, baseAiUrl, baseProxyUrl } from '../http'

export const getResult = (asin) =>
    axiosClient.post(`${baseAiUrl}/asin/est_perf`, {
        asin,
    })

export const sendFeedback = (data) =>
    axios.post(`${baseProxyUrl}/send-feedback`, data)

export const sendReport = (data) =>
    axios.post(`${baseProxyUrl}/send-report`, data)
