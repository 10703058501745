import { RouterProvider } from 'react-router'
import { useState } from 'react'
import { routes } from '../routes'
import { AppState, initialAppState } from '../context'

const App = () => {
    const [state, setState] = useState(initialAppState)

    return (
        <AppState.Provider value={{ ...state, setAppState: setState }}>
            <RouterProvider router={routes} />
        </AppState.Provider>
    )
}

export { App }
