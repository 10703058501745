import { Link } from 'react-router-dom'
import { Button } from '../../components'
import styles from './ErrorPage.module.scss'

const ErrorPage = () => {
    return (
        <section className={styles.error_page}>
            <div className={styles.main_container}>
                <span className={styles.title}>Error! Something wrong</span>
                <Link to="/" replace>
                    <Button outlined>Home</Button>
                </Link>
            </div>
        </section>
    )
}

export { ErrorPage }
