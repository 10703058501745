import { createBrowserRouter } from 'react-router-dom'
import { FeedbackPage, HomePage, KeywordsPage, ResultsPage } from './pages'
import { Layout } from './components'
import { ErrorPage } from './pages/ErrorPage'

export const routes = createBrowserRouter([
    {
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/keywords',
                element: <KeywordsPage />,
            },
            {
                path: '/results',
                element: <ResultsPage />,
            },
            {
                path: '/feedback',
                element: <FeedbackPage />,
            },
        ],
    },
])
