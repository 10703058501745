import { useIMask } from 'react-imask'
import { Controller } from 'react-hook-form'
import { TextField } from '../TextField'
import { mergeRefs } from '../../helpers'

const PhoneField = ({ control, errors, name, label }) => {
    const { ref, unmaskedValue } = useIMask({
        mask: /^[0-9\s- ]+$/,
    })

    return (
        <Controller
            control={control}
            render={({ field }) => {
                const onTextFieldChange = () => {
                    field.onChange(unmaskedValue)
                }

                return (
                    <TextField
                        ref={mergeRefs(field.ref, ref)}
                        label={label}
                        error={errors?.phone?.message}
                        type="tel"
                        onChange={onTextFieldChange}
                        onBlur={field.onBlur}
                    />
                )
            }}
            name={name}
        />
    )
}

export { PhoneField }
