import axios from 'axios'

export const baseAiUrl = process.env.REACT_APP_AI_API_URL
export const baseProxyUrl = process.env.REACT_APP_PROXY_URL

export const axiosClient = axios.create({
    auth: {
        username: process.env.REACT_APP_AI_USERNAME,
        password: process.env.REACT_APP_AI_PASSWORD,
    },
})
