import clsx from 'clsx'
import styles from './Button.module.scss'

const Button = ({
    outlined = false,
    children,
    type = 'button',
    className = '',
    ...props
}) => (
    <button
        className={clsx(
            styles.button,
            outlined && styles.button__outlined,
            className
        )}
        {...props}
        type={type}
    >
        {children}
    </button>
)

export { Button }
