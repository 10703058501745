import clsx from 'clsx'
import styles from './KeywordsInput.module.scss'
import { CheckedIcon } from '../../assets/icons'

const KeywordsInput = ({ label, keywords, onKeywordClick, error }) => {
    const keywordHandler = (keywordTitle) => {
        if (onKeywordClick) {
            onKeywordClick(keywordTitle)
        }
    }

    return (
        <div className={styles.keywords_input}>
            <span className={styles.label}>{label}</span>
            <div
                className={clsx(
                    styles.container,
                    error && styles.container__invalid
                )}
            >
                {!!keywords?.length &&
                    keywords.map(({ keyword, selected }) => (
                        <div
                            className={clsx(
                                styles.keyword,
                                selected && styles.keyword__checked
                            )}
                            key={keyword}
                            onClick={() => keywordHandler(keyword)}
                        >
                            <div className={styles.icon}>
                                <CheckedIcon />
                            </div>
                            {keyword}
                        </div>
                    ))}
                {/* <input className={styles.html_input}/> */}
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    )
}

export { KeywordsInput }
