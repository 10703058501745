import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/scss/index.scss'
import { App } from './root/App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
