import { Outlet, useLocation, useNavigate } from 'react-router'
import styles from './Layout.module.scss'
import { ArrowIcon } from '../../assets/icons'

const Layout = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const redirect = () => {
        if (pathname === '/results') {
            navigate('/keywords')
        } else if (pathname === '/keywords') {
            navigate('/')
        } else {
            navigate(-1)
        }
    }

    return (
        <div className={styles.layout}>
            {pathname !== '/' && (
                <div className={styles.to_back} onClick={() => redirect()}>
                    <ArrowIcon />
                    Back
                </div>
            )}
            <Outlet />
        </div>
    )
}

export { Layout }
