import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './Feedback.module.scss'
import { TextField, Button, PhoneField } from '../../components'
import { LockIcon } from '../../assets/icons'
import { validationSchema } from '../../helpers'
import { sendFeedback } from '../../requests'

const FeedbackPage = () => {
    const isBlur = !JSON.parse(localStorage.getItem('isContactsSent'))
    const navigate = useNavigate()

    if (!isBlur) {
        return <Navigate to="/results" replace />
    }

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        reValidateMode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
    })

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (formData) => {
        setIsLoading(true)

        sendFeedback({ ...formData, asin: localStorage.getItem('asin') })
            .then(() => {
                localStorage.setItem('isContactsSent', true)
                localStorage.setItem('fullName', formData.name)
                localStorage.setItem('email', formData.email)
                navigate('/results')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <main className={styles.feedback_page}>
            <form
                className={styles.feedback_form}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <h1 className={styles.feedback_form__title}>
                    Unlock your stats
                </h1>
                <TextField
                    {...register('name')}
                    label="name"
                    error={errors?.name?.message}
                />
                <TextField
                    {...register('email')}
                    label="email"
                    error={errors?.email?.message}
                    type="email"
                />
                <PhoneField
                    control={control}
                    name="phone"
                    errors={errors}
                    label="phone"
                />

                <Button type="submit" disabled={isLoading}>
                    <LockIcon />
                    Unlock Now
                </Button>
            </form>
        </main>
    )
}

export { FeedbackPage }
