import { useState } from 'react'
import { thousandsMask } from '../helpers'

export const usePreparedData = (keywords = []) => {
    const [mode, setMode] = useState('Year')

    const result = new Map([])
    const currentMonthNumber = new Date().getMonth() + 1

    keywords.forEach((keyword) => {
        if (keyword.selected) {
            keyword.values.forEach((value) => {
                if (result.has(value.month)) {
                    const prevData = result.get(value.month)
                    result.set(value.month, {
                        ...prevData,
                        sales_v: Math.round(prevData.sales_v + value.est_sale),
                        pv: Math.round(prevData.sales_v + value.est_sale),
                        clicks: prevData.clicks + value.clicks,
                        si: Math.round(prevData.si + value.search_impr),
                        search_v: Math.round(
                            prevData.search_v + value.monthly_sv
                        ),
                    })
                } else {
                    result.set(value.month, {
                        sales_v: Math.round(value.est_sale),
                        pv: Math.round(value.est_sale),
                        clicks: value.clicks,
                        si: value.search_impr,
                        search_v: value.monthly_sv,
                        month: value.month,
                    })
                }
            })
        }
    })

    let analyticsData = {
        sales_v: 0,
        si: 0,
        clicks: 0,
        search_v: 0,
    }

    const chartData = []
    let annualAmountSv = 0
    // eslint-disable-next-line no-restricted-syntax
    for (const value of result.values()) {
        annualAmountSv += value.sales_v
        if (mode === 'Year') {
            analyticsData = {
                sales_v: analyticsData.sales_v + value.sales_v,
                si: analyticsData.si + value.si,
                clicks: analyticsData.clicks + value.clicks,
                search_v: analyticsData.search_v + value.search_v,
            }
        }
        chartData.push(value)
    }

    const sortedChartData = chartData.sort((a, b) => a.month - b.month)

    if (mode === 'Month') {
        const currentMonthData = result.get(currentMonthNumber)
        analyticsData = {
            sales_v: currentMonthData.sales_v,
            si: currentMonthData.si,
            clicks: currentMonthData.clicks,
            search_v: currentMonthData.search_v,
        }
    }

    analyticsData = {
        sales_v: `$${thousandsMask.resolve(String(analyticsData.sales_v))}`,
        si: thousandsMask.resolve(String(analyticsData.si)),
        search_v: thousandsMask.resolve(String(analyticsData.search_v)),
        clicks: thousandsMask.resolve(String(analyticsData.clicks)),
    }

    return {
        mode,
        setMode,
        annualAmountSv,
        chartData: [
            ...sortedChartData.filter(
                (elem) => elem.month >= currentMonthNumber
            ),
            ...sortedChartData.filter(
                (elem) => elem.month < currentMonthNumber
            ),
        ],
        analyticsData,
    }
}

export const useNotifications = () => {
    const [state, setState] = useState(null)

    const showNotification = (notification, callback = null, delay = 5000) => {
        if (notification) {
            setState(notification)

            setTimeout(() => {
                setState(null)
                if (callback) {
                    callback()
                }
            }, delay)
        }
    }

    return {
        notification: state,
        showNotification,
    }
}
