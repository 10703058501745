import styles from './CustomTooltip.module.scss'
import { thousandsMask } from '../../../../helpers'
import { monthNames } from '../../../../values'

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className={styles.custom_tooltip}>
                <span className={styles.custom_tooltip__label}>
                    {monthNames[label]}
                </span>
                <span className={styles.custom_tooltip__value}>{`${
                    payload[1].name
                }: $${thousandsMask.resolve(String(payload[1].value))}`}</span>
            </div>
        )
    }

    return null
}

export { CustomTooltip }
