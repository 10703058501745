import { createContext } from 'react'

export const initialAppState = {
    keywords: null,
}

export const AppState = createContext({
    ...initialAppState,
    setAppState: () => {},
})
