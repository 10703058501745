import styles from './Loader.module.scss'

const Loader = ({ loading = true, children }) => {
    if (!loading) {
        return null
    }
    return (
        <div className={styles.loader}>
            <div className={styles.loader__wheel} />
            <span className={styles.loader__text}>{children}</span>
        </div>
    )
}

export { Loader }
