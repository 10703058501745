import { useContext, useState } from 'react'
import clsx from 'clsx'
import { Navigate, useNavigate } from 'react-router'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCurrentPng } from 'recharts-to-png'
import styles from './ResultsPage.module.scss'
import { PageTitle, Button, ResultsChart } from '../../components'
import { MailIcon, StatisticsIcon } from '../../assets/icons'
import { AppState } from '../../context'
import { useNotifications, usePreparedData } from '../../hooks'
import { statisticsTemplate } from '../../values'
import { thousandsMask } from '../../helpers'
import { sendReport } from '../../requests'

const ResultsPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { keywords } = useContext(AppState)
    const { notification, showNotification } = useNotifications()
    const [getPng, { ref }] = useCurrentPng()

    const isBlur = !JSON.parse(localStorage.getItem('isContactsSent'))
    const email = localStorage.getItem('email')

    if (!keywords?.length) {
        return <Navigate to="/" />
    }

    const { chartData, annualAmountSv, analyticsData, mode, setMode } =
        usePreparedData(keywords)

    const onUnlockStatsClick = () => {
        navigate('/feedback')
    }

    const modeButtonHandler = () => {
        if (mode === 'Year') {
            setMode('Month')
        }
        if (mode === 'Month') {
            setMode('Year')
        }
    }

    const onReportClick = async () => {
        const fullName = localStorage.getItem('fullName')
        setLoading(true)

        const data = await getPng()

        sendReport({
            fullName,
            email,
            analyticsData,
            graph: data,
            annualAmountSv: thousandsMask.resolve(String(annualAmountSv)),
        })
            .then(() => {
                showNotification(
                    {
                        type: 'success',
                        message: `Success! Message has been sent to ${email}`,
                    },
                    () => setLoading(false)
                )
            })
            .catch(() => {
                showNotification(
                    {
                        type: 'error',
                        message: `Error! Something wrong`,
                    },
                    () => setLoading(false)
                )
            })
    }

    return (
        <main className={styles.content}>
            <PageTitle>Potential sales from search</PageTitle>
            <section className={styles.container}>
                {isBlur && (
                    <div className={styles.button}>
                        <Button onClick={onUnlockStatsClick}>
                            <StatisticsIcon />
                            Unlock Your Stats
                        </Button>
                    </div>
                )}
                <div className={styles.statistics_top}>
                    <h2 className={styles.label}>Statistics</h2>
                    <div className={styles.buttons_container}>
                        <Button
                            outlined
                            disabled={mode === 'Month'}
                            onClick={modeButtonHandler}
                            className={clsx(
                                isBlur && styles.blur,
                                styles.mode_btn
                            )}
                        >
                            Month
                        </Button>
                        <Button
                            outlined
                            disabled={mode === 'Year'}
                            onClick={modeButtonHandler}
                            className={clsx(
                                isBlur && styles.blur,
                                styles.mode_btn
                            )}
                        >
                            Year
                        </Button>
                    </div>
                </div>
                <div className={styles.statistics}>
                    {statisticsTemplate.map((elem) => (
                        <div
                            key={elem.key}
                            className={clsx(
                                styles.index,
                                isBlur && elem.isBluer && styles.blur
                            )}
                        >
                            <div className={styles.index__icon}>
                                {elem.icon}
                            </div>
                            <span className={styles.index__value}>
                                {analyticsData[elem.key]}
                            </span>
                            <span className={styles.index__title}>
                                {elem.title}
                            </span>
                            <span className={styles.index__grade}>
                                {elem.grade}
                            </span>
                        </div>
                    ))}
                </div>
            </section>
            <section className={clsx(styles.container, isBlur && styles.blur)}>
                <h2 className={styles.label}>Potential annual sales graph</h2>
                <span className={styles.index__value}>
                    ${thousandsMask.resolve(String(annualAmountSv))}
                </span>
                <ResultsChart data={chartData} ref={ref} />
                <div
                    className={clsx(
                        styles.button_wrapper,
                        isBlur && styles.blur
                    )}
                >
                    <div
                        className={clsx(
                            styles.notification,
                            styles[`notification__${notification?.type}`]
                        )}
                    >
                        {notification?.message}
                    </div>
                    <Button outlined disabled={loading} onClick={onReportClick}>
                        <MailIcon />
                        Send Report
                    </Button>
                </div>
            </section>
        </main>
    )
}

export { ResultsPage }
