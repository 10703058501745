import { ChartIcon, CheckedIcon, QRCodeIcon, SearchIcon } from '../assets/icons'

export const statisticsTemplate = [
    {
        title: 'Search volume',
        key: 'search_v',
        grade: 'Good',
        isBluer: false,
        icon: <SearchIcon />,
    },
    {
        title: 'Search impression',
        key: 'si',
        grade: 'Good',
        isBluer: true,
        icon: <ChartIcon />,
    },
    {
        title: 'Search CTR (clicks)',
        key: 'clicks',
        grade: 'Good',
        isBluer: true,
        icon: <QRCodeIcon />,
    },
    {
        title: 'Sales volume',
        key: 'sales_v',
        grade: 'Good',
        isBluer: true,
        icon: <CheckedIcon />,
    },
]

export const monthNames = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
}
