import { useNavigate } from 'react-router'
import { useContext, useState } from 'react'
import styles from './KeywordsPage.module.scss'
import {
    Banner,
    KeywordsInput,
    PageTitle,
    Button,
    Loader,
} from '../../components'
import { AppState } from '../../context'

const KeywordsPage = () => {
    const { keywords, setAppState } = useContext(AppState)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const onShowResultsClick = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setTimeout(() => {
            navigate('/results')
            setIsLoading(false)
        }, 2000)
    }

    const onKeywordClick = (keywordTitle) => {
        const updatedKeywords = keywords?.map((elem) => {
            if (keywordTitle === elem.keyword) {
                return {
                    ...elem,
                    selected: !elem.selected,
                }
            }
            return elem
        })
        if (setAppState) {
            setAppState((prev) => {
                const updatedValues = { ...prev, keywords: updatedKeywords }
                if (!updatedKeywords?.filter((elem) => elem.selected).length) {
                    setError('At least one selected is required')
                } else if (error) {
                    setError(null)
                }
                return updatedValues
            })
        }
    }

    return (
        <main className={styles.keywords_page}>
            <section className={styles.main}>
                <div className={styles.content}>
                    <h2 className={styles.app_name}>ROI Calculator</h2>
                    <PageTitle>Keyword selection</PageTitle>
                    <span className={styles.description}>
                        Tap on keywords to select or deselect
                    </span>
                    <form className={styles.keywords_form}>
                        <KeywordsInput
                            keywords={keywords}
                            label={`${keywords?.length} keywords found`}
                            onKeywordClick={(keywordTitle) =>
                                onKeywordClick(keywordTitle)
                            }
                            error={error}
                        />
                        <Button
                            disabled={isLoading || error}
                            type="submit"
                            onClick={(e) => onShowResultsClick(e)}
                        >
                            Show Results
                        </Button>
                    </form>
                </div>
            </section>
            <section className={styles.side}>
                {isLoading && <Loader>Calculating Results...</Loader>}
                {!isLoading && <Banner />}
            </section>
        </main>
    )
}

export { KeywordsPage }
