import { forwardRef } from 'react'
import styles from './TextFIeld.module.scss'

const TextField = forwardRef(({ label, error, ...props }, ref) => (
    <label className={styles.text_field}>
        <span className={styles.label}>{label}</span>
        <input
            aria-invalid={!!error}
            {...props}
            className={styles.input}
            ref={ref}
        />
        {error && <p className={styles.error}>{error}</p>}
    </label>
))

export { TextField }
